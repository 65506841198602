import $ from 'jquery';
import {Modal} from "bootstrap";
import fileInput from "@/view/pages/common/fileInput.vue";
import comment from "@/view/pages/common/comment.vue";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
export default {
    components: {
        fileInput,
        comment
    },
    computed: {
        oaEmpList: {
            get() {
                return this.$store.state.systemData.oaEmpList;
            }
        },
        dayThingList: {
            get() {
                return this.$store.state.dayThingStore.dayThingList;
            }
        },
        handEmpList: {
            get() {
                return this.$store.state.dayThingStore.handEmpList;
            }
        },
        user: {
            get () {return this.$store.getters.currentUser;}
        },
    },
    watch: {
        user: function () {
            if (this.user && this.user.employeeId) {
                this.$store.dispatch('dayThingStore/getDayThingHandOver', this.user);
            }
            this.initData();
        },
        oaEmpList: function () {
            if (this.oaEmpList && this.oaEmpList.length > 0) {
                if ($('#partIn').is(':visible')) {
                    this.initSelect();
                } else {
                    if (this.temp > 50) {
                        this.temp = 0;
                    }
                    this.temp++;
                    // 递归 等待dom渲染完毕
                    const _this = this;
                    setTimeout(function () {
                        _this.initSelect();
                    }, 500);
                }
            }
        },
        handEmpList: function () {
            if ($('#handOver').is(':visible')) {
                this.initSelectThreeT('handOverDayThing', this.handOverId, this.handEmpList);
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                var _this = this;
                setTimeout(function () {
                    _this.initSelectThreeT('handOverDayThing', _this.handOverId, _this.handEmpList);
                }, 500);
            }
        },
        dayThingList: function () {
            $('#loadingModal').modal('hide');
        }
    },
    mounted() {
        this.initData();
        this.editDayThingT = new Modal(this.$refs.editDayThing);
        this.delDayThingT = new Modal(this.$refs.delDayThing);
    },
    data() {
        return {
            editStatus: true,
            dayThing: {},
            sharesId: [],
            search: {architectureF:"",architectureFour:""}, // 条件对象
            fileList: [],
            handOverId: '',
            dayThingFlag: "2",
            exportUrl:this.url + '/kybDayThing/export',
        }
    },
    methods: {
        setFileList: function (data) {
            this.fileList = data;
        },
        initSelect: function () {
            let option = '';
            option += "<option value=''>" + '请选择' + '</option>';
            this.oaEmpList.forEach(el => {
                option += "<option value='" + el.recordId + "'>" + el.name + '</option>';
            })
            $('#shareEmp').empty();
            $('#shareEmp').append(option);
            $('#shareEmp').selectpicker('val', this.sharesId);
            $('#shareEmp').selectpicker('render');
            $('#shareEmp').selectpicker('refresh');
            $('#shareEmp').selectpicker();
        },
        initData: function () {
            if (this.user && this.user.employeeId) {
                // 弹窗开始
                this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                this.$store.dispatch('dayThingStore/getDayThingHandOver', this.user);
                this.getDate();
                this.search = {employee: {}};
                if (this.handOverId) {
                    this.search.employee.recordId = this.handOverId;
                } else {
                    this.search.employee.recordId = this.user.employeeId;
                }
                this.search.startTimeTwo = $('#startTimeTwo').val();
                this.search.endTimeTwo = $('#endTimeTwo').val();
                this.$store.dispatch('dayThingStore/getDayThingList', this.search);
                //弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }
        },
        initSelectThreeT: function (id, typeId, list) {
            $('#' + id).empty();
            let option = '';
            option += "<option value=''>" + '所有' + '</option>';
            list.forEach(el => {
                option += "<option value='" + el.recordId + "'>" + el.name + '</option>';
            })
            $('#' + id).append(option);
            $('#' + id).selectpicker('val', typeId);
            $('#' + id).selectpicker('render');
            $('#' + id).selectpicker('refresh');
            $('#' + id).selectpicker();
            $('#' + id).selectpicker('val', typeId);
        },
        searchDayThing: function () {
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            this.search.recordId = null;
            this.search.employee = {};
            if (this.handOverId) {
                this.search.employee.recordId = this.handOverId;
            } else {
                this.search.employee.recordId = this.user.employeeId;
            }
            this.search.architectureId = this.search.architectureFour;
            this.$store.dispatch('dayThingStore/getDayThingList', this.search);
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        },
        // 参与/安排/下月的日程
        searchDayTing: function (num) {
            this.dayThingFlag = num;
            // 参与
            if (num === 1) {
                this.search.createdBy = {};
                this.search.shareId = '';
                this.search.createdDate = null;
                if (this.handOverId) {
                    this.search.createdBy.recordId = this.handOverId;
                } else {
                    this.search.createdBy.recordId = this.user.employeeId;
                }
            } else if (num === 2) {
                // 参与
                this.search.createdBy = {};
                this.search.shareId = '';
                this.search.createdDate = null;
                if (this.handOverId) {
                    this.search.shareId = this.handOverId;
                } else {
                    this.search.shareId = this.user.employeeId;
                }
            }else if (num === 3) {
                this.search.createdBy = {};
                this.search.shareId = '';
                this.search.createdDate = null;
            }
            this.searchDayThing();
        },

        delDayThing: function (item) {
            this.dayThing = item;
            this.delDayThingT.show();
        },
        deleteDayThing: function () {
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            this.$axios.fetchPost('kybDayThing/delDayThing', this.dayThing).then((data) => {
                if (data && data.data) {
                    if (data.data === 'success') {
                        alert('删除成功');
                    }
                    this.delDayThingT.hide();
                    this.searchDayThing();
                }
                //弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }).catch(err => {
                    console.log(err);
                }
            )
        },

        editDayThing: function (item) {
            this.editStatus = true;
            this.getDayThing(item);
        },

        viewDayThing: function (item) {
            this.editStatus = false;
            this.getDayThing(item);
        },

        getDayThing: function (item) {
            $('#file').val('');
            this.initDate();
            const seperator1 = '-';
            let date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            const currentdate = year + seperator1 + month + seperator1 + strDate;
            $('#startTime').val(currentdate);
            $('#endTime').val(currentdate);
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            if (item && item.recordId) {
                $('#loadingModal').modal('show');
                this.$axios.fetchPost('kybDayThing/getDayThing', item).then((data) => {
                    if (data && data.data) {
                        this.dayThing = data.data;
                        this.search.architectureF = data.data.architectureId;
                        this.showGroupEmp(this.dayThing);
                        $('#startTime').val(this.dayThing.showStartTime);
                        $('#endTime').val(this.dayThing.showEndTime);
                        if (data.data.attachList) {
                            this.fileList = data.data.attachList;
                        }
                        this.editDayThingT.show();
                        const depart = {};
                        if (this.user.groupId) {
                            const str = this.user.groupId.split(',');
                            depart.recordId = str[0];
                        }
                        depart.workStatus = 1;
                        this.$store.dispatch('systemData/loadOaEmpList', depart);
                        if (data.data.createdBy && data.data.createdBy.recordId && data.data.createdBy.recordId === this.user.employeeId) {
                            if (this.handOverId) {
                                if (data.data.createdBy.recordId === this.handOverId) {
                                    this.editStatus = true;
                                }
                            } else {
                                if (data.data.createdBy.recordId === this.user.employeeId) {
                                    this.editStatus = true;
                                }
                            }
                        }
                    }
                }).catch(err => {
                        console.log(err);
                    }
                )
            } else {
                this.sharesId = [];
                this.dayThing = {};
                this.fileList = [];
                const depart = {};
                if (item && item.date) {
                    $('#startTime').val(item.dateStr);
                    $('#endTime').val(item.dateStr);
                }
                if (this.user.groupId) {
                    const str = this.user.groupId.split(',');
                    depart.recordId = str[0];
                }
                depart.workStatus = 1;
                this.$store.dispatch('systemData/loadOaEmpList', depart);
                this.editDayThingT.show();
            }
            //弹窗结束
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        },

        showGroupEmp: function (item) {
            this.sharesId = [];
            const shardStr = item.shareId.split(',');
            if (shardStr && shardStr.length > 0) {
                for (let obj of shardStr) {
                    this.sharesId.push(obj);
                }
            }
        },

        weixinNotice: function (item) {
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            this.$axios.fetchPost('kybDayThing/weixinNotice', item).then((data) => {
                if (data && data.data) {
                    if (data.data === 'success') {
                        alert('推送消息成功');
                    }
                }
                //弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }).catch(err => {
                    console.log(err);
                }
            )
        },

        saveDayThing: function () {
            this.dayThing.employeeId = this.user.employeeId;
            this.user.recordId = this.user.employeeId;
            this.dayThing.createdBy = this.user;
            this.dayThing.departId = this.user.departId;
            this.dayThing.groupId = this.user.departId;
            this.dayThing.startTime = new Date($('#startTime').val());
            this.dayThing.endTime = new Date($('#endTime').val());
            if (!this.dayThing.title) {
                alert('请填写标题');
                return;
            }
            if (!this.dayThing.startTime) {
                alert('请选择开始时间');
                return;
            }
            if (!this.dayThing.endTime) {
                alert('请选择结束时间');
                return;
            }
            if (this.dayThing.endTime <= this.dayThing.startTime) {
                alert('结束时间要大于开始时间');
                return;
            }
            if (!this.dayThing.address) {
                alert('请填写地点');
                return;
            }
            if (!this.dayThing.content) {
                alert('请填写会议内容');
                return;
            }
            if (!this.sharesId || this.sharesId.length === 0) {
                alert('请选择参与人员');
                return;
            }
            this.dayThing.shareId = null;
            for (let obj of this.sharesId) {
                if (obj && obj !== '') {
                    if (this.dayThing.shareId) {
                        this.dayThing.shareId = this.dayThing.shareId + ',' + obj;
                    } else {
                        this.dayThing.shareId = obj;
                    }
                }
            }
            this.dayThing.architectureId = this.search.architectureF;
            //弹窗开始
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
            // 通过form表单进行提交
            const param = new FormData();
            param.append('dayThing', JSON.stringify(this.dayThing));
            if (this.fileList && this.fileList.length > 0) {
                for (let i = 0; i < this.fileList.length; i++) {
                    if (!this.fileList[i].downloadUrl) {
                        param.append('files', this.fileList[i]);
                    }
                }
            }
            this.$axios.formPost('kybDayThing/addDayThing', param).then((data) => {
                if (data.data === 'success') {
                    alert('提交成功');
                    this.editDayThingT.hide();
                    this.initData();
                } else if (data.data === 'false') {
                    alert('文件上传失败');
                    this.editDayThingT.hide();
                    this.initData();
                } else {
                    alert('系统错误');
                }
                //弹窗结束
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }).catch(err => {
                    console.log(err);
                }
            )
        },
        initDate: function () {
            if ($('#startTime').is(':visible')) {
                $('#startTime').daterangepicker({
                    'singleDatePicker': true,
                    'autoUpdateInput': true,
                    'showDropdowns': true,
                    'timePicker': true,
                    'timePicker24Hour': true,
                    // 'startDate': new Date(), // 设置开始日期
                    'opens': 'center',
                    'drops': 'down',
                    'locale': {
                        'format': 'YYYY-MM-DD HH:mm:ss',
                        'separator': ' - ',
                        'applyLabel': '确定',
                        'cancelLabel': '取消',
                        'fromLabel': 'From',
                        'toLabel': '到',
                        'customRangeLabel': 'Custom',
                        'weekLabel': 'W',
                        'daysOfWeek': [
                            '日',
                            '一',
                            '二',
                            '三',
                            '四',
                            '五',
                            '六'
                        ],
                        'monthNames': [
                            '一月',
                            '二月',
                            '三月',
                            '四月',
                            '五月',
                            '六月',
                            '七月',
                            '八月',
                            '九月',
                            '十月',
                            '十一月',
                            '十二月'
                        ],
                        'firstDay': 1
                    }
                }, function () {
                });
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                const _this = this;
                setTimeout(function () { _this.initDate() }, 500);
            }
            if ($('#endTime').is(':visible')) {
                $('#endTime').daterangepicker({
                    'singleDatePicker': true,
                    'autoUpdateInput': true,
                    'showDropdowns': true,
                    'timePicker': true,
                    'timePicker24Hour': true,
                    // 'startDate': new Date(), // 设置开始日期
                    'opens': 'center',
                    'drops': 'down',
                    'locale': {
                        'format': 'YYYY-MM-DD HH:mm:ss',
                        'separator': ' - ',
                        'applyLabel': '确定',
                        'cancelLabel': '取消',
                        'fromLabel': 'From',
                        'toLabel': '到',
                        'customRangeLabel': 'Custom',
                        'weekLabel': 'W',
                        'daysOfWeek': [
                            '日',
                            '一',
                            '二',
                            '三',
                            '四',
                            '五',
                            '六'
                        ],
                        'monthNames': [
                            '一月',
                            '二月',
                            '三月',
                            '四月',
                            '五月',
                            '六月',
                            '七月',
                            '八月',
                            '九月',
                            '十月',
                            '十一月',
                            '十二月'
                        ],
                        'firstDay': 1
                    }
                }, function () {
                });
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                const _this = this;
                setTimeout(function () { _this.initDate() }, 500);
            }
        },
        // 获取时间
        getDate: function () {
            let startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 2);
            let startYear = startDate.getFullYear();
            let startMonth = startDate.getMonth() + 1;
            if (startMonth.length > 1) {
                startMonth = '0' + startDate.getMonth() + 1;
            } else {
                startMonth = startDate.getMonth() + 1;
            }
            let startDay = startDate.getDate() >= 10 ? startDate.getDate() : '0' + startDate.getDate();
            let startTimeTwo = startYear + '-' + startMonth + '-' + startDay;

            let endDate = new Date();
            let endYear = endDate.getFullYear();
            let endMonth = endDate.getMonth() + 1;
            endMonth = (endMonth < 10 ? '0' + endMonth : endMonth);
            let endDay = endDate.getDate() >= 10 ? endDate.getDate() : '0' + endDate.getDate();
            let endTimeTwo = (endYear + '-' + endMonth + '-' + endDay);

            this.initQueryDate('startTimeTwo', startTimeTwo);
            this.initQueryDate('endTimeTwo', endTimeTwo);
        },
        initQueryDate: function (id, startDates) {
            if ($('#' + id + '').is(':visible')) {
                const _this = this
                $('#' + id + '').daterangepicker({
                    'singleDatePicker': true,
                    'showDropdowns': true,
                    'timePicker': true,
                    'timePicker24Hour': false,
                    'startDate': startDates, // 设置开始日期
                    'opens': 'center',
                    'drops': 'down',
                    'locale': {
                        'format': 'YYYY-MM-DD',
                        'separator': ' - ',
                        'applyLabel': '确定',
                        'cancelLabel': '取消',
                        'fromLabel': 'From',
                        'toLabel': '到',
                        'customRangeLabel': 'Custom',
                        'weekLabel': 'W',
                        'daysOfWeek': [
                            '日',
                            '一',
                            '二',
                            '三',
                            '四',
                            '五',
                            '六'
                        ],
                        'monthNames': [
                            '一月',
                            '二月',
                            '三月',
                            '四月',
                            '五月',
                            '六月',
                            '七月',
                            '八月',
                            '九月',
                            '十月',
                            '十一月',
                            '十二月'
                        ],
                        'firstDay': 1
                    }
                }, function (start) {
                    if (id === 'startTimeTwo') {
                        _this.search.startTimeTwo = start.format('YYYY-MM-DD');
                    } else if (id === 'endTimeTwo') {
                        _this.search.endTimeTwo = start.format('YYYY-MM-DD');
                    }
                    _this.search.manage = _this.user.manage;
                    _this.$store.dispatch('dayThingStore/getDayThingList', _this.search);
                })
            } else {
                if (this.temp > 50) {
                    this.temp = 0;
                }
                this.temp++;
                // 递归 等待dom渲染完毕
                const _this = this
                setTimeout(function () { _this.initQueryDate(id, startDates) }, 500);
            }
        },
    },
}